body {
    height: 100vh;    
    overflow: scroll;
}

button:focus, .btn:focus { box-shadow: none!important; }

#logo { max-height: 2rem;}

.turquoise-bg { background-color: #00f5c8; }
.dark-bg { background-color: #1e3c5a; }
.blue-bg { background-color: #50b4ff;}

.text-yellow { color: #ffff32; }

.btn.btn-primary {
    color: white;
    background-color: #1e3c5a;
    border-color: #1e3c5a;
}


h1, h2, h3, h4, h5 {
    color: #1e3c5a;
}

.form-control:focus { box-shadow: none!important; border-color: #1e3c5a; }

li          .descreet { visibility: hidden; }
li:hover    .descreet { visibility: visible; }

.nav-link, .nav-link:hover { color: #1e3c5a; text-decoration: none }
.nav-link.active { color: #1e3c5a; font-weight: bold; }
.nav-highlight { visibility: hidden; color:#ffff32; }
.nav-link.active > .nav-highlight { visibility: visible; }

.creditCard { border: 1px solid #dbdbdb; border-radius: 2px; color: #1e3c5a;}
.creditCard.primary { font-weight: bold; }
.creditCard > .card-actions { display: none;}
.creditCard:hover > .card-actions { display: block;}